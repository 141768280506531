"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.updateCrmContact = exports.updateContactInformation = exports.loadDMSCustomer = exports.loadDMSAccountMatches = exports.loadContactSummary = exports.linkMatchDMSAccount = exports.linkDMSAccount = exports.createDMSAccount = void 0;
var _request = require("../../tools/request");
const CRM_API_URL = process.env.REACT_APP_CRM_API_URL;

/**
 * @param {String} contactId
 * @param {String} organizationUnitId
 * @param {Object} params
 * @returns {Promise}
 */
const loadContactSummary = (contactId, organizationUnitId) => {
  return (0, _request.get)(`${CRM_API_URL}/contacts/${contactId}/summary`, {
    params: {
      organizationUnitId
    }
  });
};

/**
 * @param {String} organizationUnitId
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.loadContactSummary = loadContactSummary;
const loadDMSAccountMatches = (organizationUnitId, queryParams) => {
  const url = `${CRM_API_URL}/contacts/cdk/${organizationUnitId}/customer/search`;
  return (0, _request.get)(url, {
    params: queryParams
  });
};

/**
 * @param {String} organizationUnitId
 * @param {String} clientNumber
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.loadDMSAccountMatches = loadDMSAccountMatches;
const linkDMSAccount = (organizationUnitId, clientNumber, queryParams) => {
  const url = `${CRM_API_URL}/contacts/cdk/${organizationUnitId}/customer/${clientNumber}/update`;
  return (0, _request.put)(url, null, {
    params: queryParams
  });
};

/**
 * @param {String} organizationUnitId
 * @param {String} clientNumber
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.linkDMSAccount = linkDMSAccount;
const linkMatchDMSAccount = (organizationUnitId, clientNumber, queryParams) => {
  const url = `${CRM_API_URL}/contacts/cdk/${organizationUnitId}/customer/${clientNumber}/update-match`;
  return (0, _request.put)(url, null, {
    params: queryParams
  });
};

/**
 * @param {String} organizationUnitId
 * @param {Object} queryParams
 * @returns {Promise}
 */
exports.linkMatchDMSAccount = linkMatchDMSAccount;
const createDMSAccount = (organizationUnitId, queryParams) => {
  const url = `${CRM_API_URL}/contacts/cdk/${organizationUnitId}/customer/create`;
  return (0, _request.post)(url, null, {
    params: queryParams
  });
};

/**
 * @param {String} organizationUnitId
 * @param {String} clientNumber
 * @returns {Promise}
 */
exports.createDMSAccount = createDMSAccount;
const loadDMSCustomer = (organizationUnitId, clientNumber) => {
  const url = `${CRM_API_URL}/contacts/cdk/${organizationUnitId}/customer/${clientNumber}`;
  return (0, _request.get)(url);
};

/**
 * @param {Object} queryParams
 * @param {Object} body
 * @param {Number} clientNumber
 * @returns {Promise}
 */
exports.loadDMSCustomer = loadDMSCustomer;
const updateContactInformation = (contactId, queryParams, requestBody) => {
  const url = `${CRM_API_URL}/contacts/${contactId}/deskingUpdate`;
  return (0, _request.put)(url, requestBody, {
    params: queryParams
  });
};

/**
 * @param {Number} contactId
 * @param {Object} body
 * @returns {Promise}
 */
exports.updateContactInformation = updateContactInformation;
const updateCrmContact = async (contactId, requestBody) => {
  const url = `${CRM_API_URL}/contacts/${contactId}`;
  return await (0, _request.put)(url, requestBody);
};
exports.updateCrmContact = updateCrmContact;